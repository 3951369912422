@import "../../assets/styles/variables";

.back {
  color: $primary;
  &:hover {
    color: $primary_hover;
    text-decoration: underline;
    cursor: pointer;
  }
}

.content-box {
  font-weight: 500;
  .list-group-item {
    word-break: break-all;
    padding: 5px 10px;
  }
  .sub-heading {
    font-weight: normal !important;
  }
}

.data-table {
  padding-top: 10px;
}

.orange-table {
  .custom-table {
    .rdt_TableHeadRow {
      background-color: #c94e14;
    }
  }
}

.search-vin {
  padding-top: 10px;
}

.content-box {
  i {
    font-size: 19px;
  }
}

.scheduleDetailProgress {
  .progress {
    height: 100%;
  }
}

.detail-card {
  .content-box {
    font-size: 14px;
  }
}

.manual-ref {
  font-size: 20px;
}

.refresh-icon {
  animation: rotating 1s linear infinite;
  transform-origin: center;
  transform-box: fill-box;
}

@keyframes rotating {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.beforeVersion {
  background: #dcdcdc;
  color: #333;
  padding: 2px;
  border-radius: 3px;
  font-size: 14px;
}

.afterVersion {
  background-color: rgba(155, 206, 253, 0.7);
  color: #333;
  font-size: 14px;
  padding: 2px;
  border-radius: 3px;
}

#tooltip-disabled > .tooltip-inner {
  text-align: left;
  max-width: none;
}
#bulk-tooltip > .tooltip-inner {
  text-align: left;
  max-width: none;
}


code {
  white-space: break-spaces;
}

.schedule-vehicle-count {
  list-style: none;
  margin: 0 10px;
  padding: 20px 0px;
  li {
    display: inline-block;
    // margin-right: 35px;
    width: 16%;
    .count-value {
      font-size: 35px;
      line-height: 28px;
    }
    .text {
      font-size: 14px;
      color: gray;
    }
  }
}

.vehicle-activity-tabs {
  .nav-link {
    padding: 0.5rem 1rem !important;
  }
  .nav-link.active {
    color: #495057 !important;
  }
}

.activity-schedule-list {
  .list-group-item {
    font-size: 14px;
    .name {
      font-size: 15px;
      font-weight: 500;
    }
  }
}
.gap-sm {
  margin-top: 10px;
  margin-bottom: 5px;
}
code {
  color: red;
}

code.info {
  color: #004085;
  background-color: #cce5ff;
  border-color: #b8daff;
  padding: 5px 10px;
  margin-bottom: 1rem;
  border: 1px solid transparent ;
  border-radius: 5px;
  font-weight: 600!important;
}
code.error {
  color: #721c24;
  background-color: #f8d7da;
  border-color: #f5c6cb;
  padding: 5px 10px;
  margin-bottom: 1rem;
  border: 1px solid transparent ;
  border-radius: 5px;
  font-weight: 600!important;
}
code.inprogress {
  color: #856404;
  background-color: #fff3cd;
  border-color: #ffeeba;
  padding: 5px 10px;
  margin-bottom: 1rem;
  border: 1px solid transparent ;
  border-radius: 5px;
  font-weight: 600!important;
}

code.success {
  color: #155724;
  background-color: #bceec7;
  border-color: #98dca8;
  padding: 5px 10px;
  margin-bottom: 1rem;
  border: 1px solid transparent;
  border-radius: 5px;
  font-weight: 600!important;
}
code.muted {
  color: #1b1e21;
  background-color: #d6d8d9;
  border-color: #c6c8ca;
  padding: 5px 10px;
  margin-bottom: 1rem;
  border: 1px solid transparent;
  border-radius: 5px;
  font-weight: 600!important;

}

li.activity{
  margin-top: 5px;
 // font-size: 14px;
  border-bottom: 1px solid #eee;
}