@import url(https://fonts.googleapis.com/css?family=Roboto:400,500&display=swap);
body {
  color: #2D2D2D; }

.relative {
  position: relative; }

.primary-bg {
  background-color: #1488c9; }

.primary-text {
  color: #1488c9; }

button.primary-bg:hover, button.primary-bg:active {
  background-color: #1278b2; }

.page-title {
  -webkit-align-items: center;
          align-items: center; }
  .page-title h1 {
    line-height: 54px;
    font-size: 24px !important;
    font-weight: 400; }

.form-control.is-valid .form-control:valid, .form-control.is-valid .form-control:invalid, .was-validated .form-control:valid, .was-validated .form-control:invalid {
  background-image: none; }

.action-btn-icon {
  font-size: 12px;
  padding-right: 3px; }

.action-btn {
  font-weight: 500;
  font-size: .8rem; }

.approve-btn {
  background-color: #1488c9; }

.text-ellipses {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  font-weight: normal; }
  .text-ellipses span {
    font-weight: bold; }

.toast {
  font-size: 15px;
  width: 380px !important; }

.toast-body {
  max-height: 80vh;
  overflow-x: hidden;
  overflow-y: auto; }

.custom-pagination {
  margin-top: 10px; }
  .custom-pagination .pagination {
    float: right; }

.width100 {
  width: 100%; }

.longString {
  word-break: break-all; }

.bold {
  font-weight: 500; }

.radioButton .btn-primary.disabled, .radioButton .btn-primary:disabled {
  color: #fff;
  background-color: gray;
  border-color: gray; }

.btn-group.btn-group-toggle {
  z-index: 0; }

.dropdown .dropdown-menu {
  transition: all 0.5s;
  max-height: 0;
  display: block;
  overflow: hidden;
  opacity: 0; }

.dropdown.show .dropdown-menu {
  /* For Bootstrap 4, use .dropdown.show instead of .dropdown.open */
  max-height: 500px;
  opacity: 1; }

.second-level-modal {
  z-index: 1050; }

.custom-file-label {
  z-index: 0; }

body {
  margin: 0;
  font-family: 'Roboto', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale; }

.main-content {
  min-height: 90vh;
  padding: 78px 15px 20px; }
  .main-content ::-webkit-scrollbar {
    z-index: 30;
    width: 10px;
    height: 12px; }
  .main-content ::-webkit-scrollbar-track {
    background-color: rgba(187, 184, 184, 0.466);
    border-left: 1px solid #c1c1c1;
    border-radius: 20px;
    box-shadow: none;
    border: 0; }
  .main-content ::-webkit-scrollbar-thumb {
    background-color: rgba(0, 0, 0, 0.281);
    border-radius: 20px;
    box-shadow: none;
    border: 0; }
    .main-content ::-webkit-scrollbar-thumb:hover {
      background-color: rgba(7, 7, 7, 0.521); }

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace; }


.custom-table {
  border-radius: .3rem;
  border: 1px solid rgba(0, 0, 0, 0.14); }
  .custom-table .rdt_TableHeader {
    display: none; }
  .custom-table .rdt_TableHeadRow {
    background-color: #1488c9;
    color: white; }
  .custom-table .rdt_TableBody .rdt_TableRow {
    color: rgba(0, 0, 0, 0.87) !important; }

.schedules-page-title {
  -webkit-align-items: center;
          align-items: center;
  border-bottom: 2px solid rgba(0, 0, 0, 0.14); }
  .schedules-page-title h1 {
    font-size: 24px !important; }

.data-table {
  padding-top: 30px; }

.expiry-text span {
  background-color: transparent;
  border: none;
  font-size: 14px; }

.tooltip-toggle {
  cursor: pointer;
  width: 100%;
  height: 100%; }
  .tooltip-toggle::before {
    position: absolute;
    background-color: #2b222a;
    border-radius: 5px;
    color: #fff;
    padding: 10px !important;
    content: attr(tooltip);
    padding: 0rem 0.3rem;
    text-transform: none;
    transition: all 0.5s ease;
    top: 0;
    left: 30%;
    z-index: 3; }
  .tooltip-toggle::before, .tooltip-toggle::after {
    color: #efefef;
    font-size: 14px;
    opacity: 0;
    pointer-events: none;
    text-align: center; }
  .tooltip-toggle:hover::before, .tooltip-toggle:hover::after {
    opacity: 1;
    transition: all 0.75s ease; }

.cancelled-bar {
  background-color: #ffa500; }

.inprogress-bar {
  background-color: #efef19; }

.newSchedBtn .dropdown-menu {
  top: -5px !important; }

.filterBtn .dropdown-item {
  padding: 10px 25px; }

.removeRowButton {
  margin-top: 31px; }

.create-schedule-card .list-group-item {
  padding: 0.3rem 0.75rem; }
  .create-schedule-card .list-group-item b {
    font-weight: 500; }

.bulk-vin-preview-table {
  max-height: 320px;
  overflow: auto; }
  .bulk-vin-preview-table th {
    font-weight: 500; }

.search-box button {
  width: 100%; }

.modal-backdrop.show {
  opacity: 0.5 !important; }

.modal-content {
  box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.16), 0 2px 10px 0 rgba(0, 0, 0, 0.12) !important; }
  .modal-content .modal-header {
    border-bottom: 0px; }
  .modal-content .modal-footer {
    border-top: 0px; }

.create-button {
  padding-bottom: 5px;
  padding-top: 8px;
  border: 0;
  font-size: 14px;
  font-weight: 500;
  -webkit-align-items: center !important;
          align-items: center !important; }
  .create-button .name {
    vertical-align: super; }
  .create-button div {
    display: inline; }
  .create-button i {
    font-size: 25px;
    padding-right: 8px; }
  .create-button:hover {
    color: #99d0eb; }
  .create-button:focus {
    outline: none !important; }

@media (max-width: 576px) {
  .create-button .name {
    display: none; } }


#notfound {
  position: relative;
  height: 60vh; }

#notfound .notfound {
  position: absolute;
  left: 50%;
  top: 60%;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%); }

.notfound {
  max-width: 520px;
  width: 100%;
  line-height: 1.4;
  text-align: center; }

.notfound .notfound-404 {
  position: relative;
  height: 240px; }

.notfound .notfound-404 h1 {
  font-family: "Montserrat", sans-serif;
  position: absolute;
  left: 50%;
  top: 50%;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  font-size: 252px;
  font-weight: 900;
  margin: 0px;
  color: #6ebce2;
  text-transform: uppercase;
  letter-spacing: -40px;
  margin-left: -20px; }

.notfound .notfound-404 h1 > span {
  text-shadow: -8px 0px 0px #fff; }

.notfound .notfound-404 h3 {
  font-family: "Cabin", sans-serif;
  position: relative;
  font-size: 16px;
  font-weight: 700;
  text-transform: uppercase;
  color: #1488c9;
  margin: 0px;
  letter-spacing: 3px;
  padding-left: 6px; }

.notfound h2 {
  font-size: 20px;
  font-weight: 400;
  text-transform: uppercase;
  color: #1488c9;
  margin-top: 0px;
  margin-bottom: 25px; }

@media only screen and (max-width: 767px) {
  .notfound .notfound-404 {
    height: 200px; }
  .notfound .notfound-404 h1 {
    font-size: 200px; } }

@media only screen and (max-width: 480px) {
  .notfound .notfound-404 {
    height: 162px; }
  .notfound .notfound-404 h1 {
    font-size: 162px;
    height: 150px;
    line-height: 162px; }
  .notfound h2 {
    font-size: 16px; } }

.navbar {
  background: #fff !important;
  border-bottom: 1px solid rgba(0, 0, 0, 0.14) !important;
  box-shadow: 0 0.125rem 0.3125rem rgba(0, 0, 0, 0.26); }
  .navbar .navbar-nav {
    font-weight: 500 !important; }
  .navbar .navbar-collapse {
    background: #fff !important; }
  .navbar .sub-head {
    color: rgba(0, 0, 0, 0.5); }

.fixed-top {
  height: 64px !important; }

.nav-link {
  display: block;
  padding: 1rem 1rem !important;
  text-decoration: none;
  background-color: transparent; }

.navbar-light .navbar-nav .active > .nav-link, .navbar-light .navbar-nav .nav-link.active, .navbar-light .navbar-nav .nav-link.show, .navbar-light .navbar-nav .show > .nav-link {
  color: #1488c9; }

@media (min-width: 576px) {
  .nav-link {
    padding-right: 1.5rem !important;
    padding-left: .5rem !important; } }

.nav-username {
  font-size: 18px; }

.nav-link.active {
  color: #3a7fd5 !important; }

.footer-main {
  height: 10vh;
  background-color: #fff;
  border-top: 1px solid rgba(0, 0, 0, 0.14); }

.login-container {
  width: 100%;
  height: 100vh;
  background-image: url(/static/media/login.289c65cc.png);
  background-attachment: fixed;
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  position: relative;
  overflow: hidden; }
  .login-container .form-container {
    border-radius: 10px;
    background: #fff;
    padding: 20px;
    min-width: 400px;
    position: absolute;
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
            transform: translate(-50%, -50%); }
    .login-container .form-container input {
      height: 52px;
      padding-left: 43px; }
  .login-container .login-form {
    max-width: 400px; }

.label-icon {
  color: #939393;
  position: absolute;
  left: 10px;
  top: 15px; }

.login-icon {
  background-color: #1488c9;
  padding: 15px;
  border-radius: 50%;
  color: #fff;
  position: absolute;
  top: 0;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%); }

.back {
  color: #1488c9; }
  .back:hover {
    color: #6ebce2;
    text-decoration: underline;
    cursor: pointer; }

.content-box {
  font-weight: 500; }
  .content-box .list-group-item {
    word-break: break-all;
    padding: 5px 10px; }
  .content-box .sub-heading {
    font-weight: normal !important; }

.data-table {
  padding-top: 10px; }

.orange-table .custom-table .rdt_TableHeadRow {
  background-color: #c94e14; }

.search-vin {
  padding-top: 10px; }

.content-box i {
  font-size: 19px; }

.scheduleDetailProgress .progress {
  height: 100%; }

.detail-card .content-box {
  font-size: 14px; }

.manual-ref {
  font-size: 20px; }

.refresh-icon {
  -webkit-animation: rotating 1s linear infinite;
          animation: rotating 1s linear infinite;
  -webkit-transform-origin: center;
          transform-origin: center;
  transform-box: fill-box; }

@-webkit-keyframes rotating {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg); }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg); } }

@keyframes rotating {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg); }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg); } }

.beforeVersion {
  background: #dcdcdc;
  color: #333;
  padding: 2px;
  border-radius: 3px;
  font-size: 14px; }

.afterVersion {
  background-color: rgba(155, 206, 253, 0.7);
  color: #333;
  font-size: 14px;
  padding: 2px;
  border-radius: 3px; }

#tooltip-disabled > .tooltip-inner {
  text-align: left;
  max-width: none; }

#bulk-tooltip > .tooltip-inner {
  text-align: left;
  max-width: none; }

code {
  white-space: break-spaces; }

.schedule-vehicle-count {
  list-style: none;
  margin: 0 10px;
  padding: 20px 0px; }
  .schedule-vehicle-count li {
    display: inline-block;
    width: 16%; }
    .schedule-vehicle-count li .count-value {
      font-size: 35px;
      line-height: 28px; }
    .schedule-vehicle-count li .text {
      font-size: 14px;
      color: gray; }

.vehicle-activity-tabs .nav-link {
  padding: 0.5rem 1rem !important; }

.vehicle-activity-tabs .nav-link.active {
  color: #495057 !important; }

.activity-schedule-list .list-group-item {
  font-size: 14px; }
  .activity-schedule-list .list-group-item .name {
    font-size: 15px;
    font-weight: 500; }

.gap-sm {
  margin-top: 10px;
  margin-bottom: 5px; }

code {
  color: red; }

code.info {
  color: #004085;
  background-color: #cce5ff;
  border-color: #b8daff;
  padding: 5px 10px;
  margin-bottom: 1rem;
  border: 1px solid transparent;
  border-radius: 5px;
  font-weight: 600 !important; }

code.error {
  color: #721c24;
  background-color: #f8d7da;
  border-color: #f5c6cb;
  padding: 5px 10px;
  margin-bottom: 1rem;
  border: 1px solid transparent;
  border-radius: 5px;
  font-weight: 600 !important; }

code.inprogress {
  color: #856404;
  background-color: #fff3cd;
  border-color: #ffeeba;
  padding: 5px 10px;
  margin-bottom: 1rem;
  border: 1px solid transparent;
  border-radius: 5px;
  font-weight: 600 !important; }

code.success {
  color: #155724;
  background-color: #bceec7;
  border-color: #98dca8;
  padding: 5px 10px;
  margin-bottom: 1rem;
  border: 1px solid transparent;
  border-radius: 5px;
  font-weight: 600 !important; }

code.muted {
  color: #1b1e21;
  background-color: #d6d8d9;
  border-color: #c6c8ca;
  padding: 5px 10px;
  margin-bottom: 1rem;
  border: 1px solid transparent;
  border-radius: 5px;
  font-weight: 600 !important; }

li.activity {
  margin-top: 5px;
  border-bottom: 1px solid #eee; }

.search-box {
  width: 100%; }

.left-menu a {
  border: 0.1rem solid;
  background-color: #1488c9;
  color: white; }

.left-menu .nav-item .nav-link.active {
  background: #6ebce2 !important;
  color: white !important; }

.left-menu a {
  border: 0.1rem solid;
  background-color: #1488c9;
  color: white; }

.left-menu .nav-item .nav-link.active {
  background: #6ebce2 !important;
  color: white !important; }

@media (max-width: 576px) {
  .sync-button .name {
    display: none; } }

.action-row-top {
  line-height: 50px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.125); }

.dropdown {
  overflow: visible !important; }

.outer-container {
  background: #f4f7fe;
  border-radius: 10px; }

.fancy {
  width: 100%;
  text-align: left;
  border-bottom: 1px solid #afafaf7a;
  line-height: 0.1em;
  margin: 10px 0 20px; }

.fancy span {
  background: white;
  padding: 0 10px; }

.primaryContainer {
  border-radius: 10px;
  text-align: center;
  background: white;
  box-shadow: 0 10px 15px -3px rgba(0, 0, 0, 0.1), 0 4px 6px -2px rgba(0, 0, 0, 0.05); }
  .primaryContainer h5 {
    font-weight: 400;
    padding: 20px;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px; }
  .primaryContainer .count-wrapper {
    padding-top: 10px; }

.primaryContainer.summary {
  height: 200px;
  margin-bottom: 20px; }
  .primaryContainer.summary h5 {
    background: #08279c;
    color: white; }
  .primaryContainer.summary .count-container {
    width: 50%; }

.primaryContainer.details {
  height: 390px;
  margin-bottom: 20px; }
  .primaryContainer.details .count-container {
    width: 20%; }
  .primaryContainer.details h6 {
    padding-top: 30px; }

.primaryContainer.handler {
  height: 380px;
  margin-bottom: 20px; }
  .primaryContainer.handler h5 {
    color: white;
    background: #ed545c; }
  .primaryContainer.handler .count-wrapper {
    padding-top: 0px; }
    .primaryContainer.handler .count-wrapper .count-container {
      display: block;
      width: 100%; }

.primaryContainer.sync {
  height: 210px;
  overflow: auto; }
  .primaryContainer.sync h5 {
    padding: 15px;
    color: white;
    background: #008376; }
  .primaryContainer.sync table {
    text-align: left;
    margin-top: 20px; }

.count-container {
  display: inline-block; }
  .count-container .count {
    font-size: 40px;
    color: gray;
    line-height: 1.4; }
  .count-container .text {
    font-weight: normal;
    font-size: 18px;
    line-height: 1.4;
    color: #172b4d; }

.calendarOverlay {
  background-color: #fff;
  right: 0;
  position: absolute;
  z-index: 9;
  box-shadow: 0 20px 25px -5px rgba(0, 0, 0, 0.1), 0 10px 10px -5px rgba(0, 0, 0, 0.04); }
  .calendarOverlay .react-calendar {
    border: none;
    border-radius: 5px;
    display: inline-block;
    transition: display 0.5s linear; }

.border-right {
  border-right: 1px solid #d8d7d7; }

